<template>
    <div class="signInBox">
        <header class="headerBox"></header>
        <div class="accumulatedBox">
            <header>{{ $t('ramadan_signin_accumulated') }}</header>
            <div class="currBox">
                <p class="current">{{ $t('ramadan_signin_current') }} : {{ days}} {{ $t('ramadan_signin_days') }}</p>
                <div>
                    <div class="progressBox">
                        <p :style="'width:' + signPro[signIndex] + '%'">
                            <span :style="'width:' + updateProgressBar(days)"></span>
                        </p>
                    </div>
                    <div class="giftBox">
                        <div class="giftItem" v-for="item in rewards" :key="item.days">
                            <div class="iconBox">
                                <span class="boxIcon" :class="{'openBoxIcon':item.state == 1,'boxIconShow':days >= item.days &&  item.state == 0}"></span>
                            </div>
                            <div class="giftInfo" :class="days >= item.days &&  item.state == 0 ? 'openGiftInfo' : ''" @click="getReward(item)">
                                <!-- 已领取 -->
                                <span class="receivedBg" v-if="item.state == 1"></span>
                                <img :src="item.goods[0].url" alt="">
                                <span>{{ item.days }} {{ $t('ramadan_signin_days') }}</span>
                            </div>
                            <div class="giftName">
                                <p>{{item.goods[0].name}}</p>
                                <p> *{{item.goods[0].num}} {{ item.goods[0].goodId > 0 ? $t('ramadan_signin_days') : ''}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="signInItem">
                <p class="noteBox">{{ $t('ramadan_signin_note') }}</p>
                <div class="signInDayBox" ref="scrollParent">
                    <div class="signInDayItem" v-for="item in signs" :key="item.day" :ref="item.day == curday?'fixedChild':''" @click="getSignReward(item)">
                        <!-- 非开斋日 -->
                        <div v-if="item.type == 0" class="timeDay" :class="item.timeState == 1 ? 'expireDay' : 'actTimeDay'">{{ item.day.toString().substr(4) | addString }}</div>
                        <!-- 开斋日 -->
                        <div v-else class="timeDay yTimeDay">{{ item.day.toString().substr(4) | addString }}</div>
                        <div class="giftMsg " :class="{'giftActBg':item.type == 1,'giftMsgShow':item.day == curday && (item.state == 0 || (item.pay == 1 && item.payState == 0))}">
                            <img :src="item.goods[0].url" alt="">
                            <!-- 过期的状态 -->
                            <template v-if="curday > item.day">
                                <template v-if="item.state == 1 || item.payState == 1">
                                    <!-- 已领取 -->
                                    <span class="receivedBg" v-if="item.state == 1"></span>
                                    <i v-if="item.state == 1"><i></i></i>
                                    <!-- 2倍领取 -->
                                    <span v-if="item.payState == 1" class="twoIcon"></span>
                                </template>
                                <!-- 过期 -->
                                <template v-else>
                                    <span  class="expiredBg"></span>
                                </template>
                            </template>
                            <template v-else>
                                 <!-- 已领取 -->
                                <span class="receivedBg" v-if="item.state == 1"></span>
                                <i v-if="item.state == 1"><i></i></i>
                                <!-- 2倍领取 -->
                                <span v-if="item.pay == 1" class="twoIcon"></span>
                            </template>
                            
                        </div>
                        <div class="giftName">
                            <p>{{item.goods[0].name}}</p>
                            <p> *{{item.goods[0].num}} {{ item.goods[0].goodId > 0 ? $t('ramadan_signin_days') : ''}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="popBox" v-if="popFlag">
            <!-- 签到弹窗 -->
            <div class="popItem" v-if="popFlag == 2">
                <p class="popTitle">{{ $t('ramadan_signin_receivedok') }}</p>
                <div class="line"></div>
                <div class="popTime">
                    {{ popMsg.day.toString().substr(4) | addString}}
                </div>
                <div class="popInfoBox">
                    <img :src="popMsg.goods[0].url" alt="">
                    <span></span>
                    <i><i></i></i>
                    <span class="twoIcon" v-if="popMsg.pay"></span>
                </div>
                <p class="popGiftName">{{ popMsg.goods[0].name }}*{{popMsg.goods[0].num}} {{ popMsg.goods[0].goodId > 0 ? $t('ramadan_signin_days') : ''}}</p>
                <div class="closeBox" @click="closePop"></div>
            </div>
            <!-- 累计弹窗 -->
            <div class="popItem popItem2" v-if="popFlag == 1">
                <div class="popItem2Title">{{ $t('ramadan_signin_accumulated') }}</div>
                <p class="popTitle">{{ $t('ramadan_signin_receivedok') }}</p>
                <div class="line"></div>
                <div class="popTime">
                    {{ popMsg.days + $t('ramadan_signin_days') }}
                </div>
                <div class="popInfoBox">
                    <img :src="popMsg.goods[0].url" alt="">
                    <span>{{ $t('ramadan_signin_received') }}</span>
                    <i><i></i></i>
                    <span class="twoIcon" v-if="popMsg.pay"></span>
                </div>
                <p class="popGiftName">{{ popMsg.goods[0].name }}*{{popMsg.goods[0].num}} {{ popMsg.goods[0].goodId > 0 ? $t('ramadan_signin_days') : ''}}</p>
                <div class="closeBox" @click="closePop"></div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import { Toast } from "vant";
  export default {
    name: "ar_sign_in",
    data() {
      return{
        popFlag: 0,
        signInDay:[0,4,11,18,25,28],
        signPro:[0,10,39,64,90,100],
        signIndex:0,
        // 累计天数
        days:0,
        // 累计奖励
        rewards:[],
        // 签到奖励
        signs:[],
        popMsg:{},
        // 当前天
        curday:''
      }
    },
    created() { 
      //调用原生方法
      this.$completes.complete("getUserInfo");
      //原生调用h5方法
      window.getUserInfo = this.getUserInfo;
      window["getUserInfo"] = (data) => {
        this.getUserInfo(data);
      };
  
    //   this.getUserInfo();
    },
    computed:{
    },
    filters:{
        addString(str) {
            return str.substring(0, 2) + '/' + str.substring(2);
        }
    },
    methods: {
        // 关闭弹窗
        closePop(){
            this.popFlag = 0;
            this.popMsg = {};
        },
        // 更新累计奖励进度条
        updateProgressBar(value) {
            if(value >= this.signInDay[0] && value < this.signInDay[1]){
                this.signIndex = 1;
            }else if(value >= this.signInDay[1] && value < this.signInDay[2]){
                this.signIndex = 2;
            }else if(value >= this.signInDay[2] && value < this.signInDay[3]){
                this.signIndex = 3;
            }else if(value >= this.signInDay[3] && value < this.signInDay[4]){
                this.signIndex = 4;
            }else if(value >= this.signInDay[4] && value < this.signInDay[5]){
                this.signIndex = 5;
            }else{
                this.signIndex = 5;
            }
            return value / this.signInDay[this.signIndex] * 100 + '%'
        },
        // 领取签到奖励
        getSignReward(item){
            if(item.day == this.curday &&  (item.state == 0 || (item.pay == 1 && item.payState == 0))){
                this.popMsg = item;
                // 获取活动时间
                let personLeveldatas = {
                option: "get",
                host:
                    this.$serviceIpJava +
                    "api/activity/ramadan/signin/signin?token=" +
                    this.token +
                    "&uid=" + 
                    this.uid,
                data: "",
                lang: this.langs,
                header: this.headers,
                };
                this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
                    if (data.code == 200) {
                        this.popFlag = 2;
                        this.days = data.data.days;
                        this.signs = data.data.signs
                    }else{
                        Toast(data.message);
                        this.popMsg = {};
                    }
                });
            }
        },
        // 领取累计奖励
        getReward(item){
            if(this.days >= item.days &&  item.state == 0){
                this.popMsg = item;
                // 获取活动时间
                let personLeveldatas = {
                option: "get",
                host:
                    this.$serviceIpJava +
                    "api/activity/ramadan/signin/reward?token=" +
                    this.token +
                    "&uid=" + 
                    this.uid+
                    "&day=" + item.days,
                data: "",
                lang: this.langs,
                header: this.headers,
                };
                this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
                    if (data.code == 200) {
                        this.popFlag = 1;
                        item.state = 1;
                        console.log(this.popMsg);
                    }else{
                        Toast(data.message);
                        this.popMsg = {};
                    }
                });
            }
        },
        getUserInfo(data) {
            this.userInfo = JSON.parse(data);
            this.token = JSON.parse(data).token
            this.uid = JSON.parse(data).userId
            let langs = this.userInfo.lang.substring(0,2)
            this.langs = this.userInfo.lang.substring(0,2)
            let lang = this.userInfo.lang
            this.headers = {
                fid:  this.userInfo.fid,
                os:  this.userInfo.os || 1,
                lang: this.userInfo.lang, 
                version:this.userInfo.version,
                deviceid:this.userInfo.deviceId,
              }
        // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJNak0wTkE9PSIsInh5eiI6IlpXWTNaRFpqWXpBMVlUaG1OR0prWW1FeFlqUTFaVFprWkRKbE5tSXdZbVU9IiwiZXhwIjoxOTI3MDc3NjQ0fQ.z0tUxhsHtwXUK_T6iRQn4M4OvPnPraKDu-JuPDNTJ1g';
        //     this.uid = '2344'; 
        //     this.headers = {
        //         fid: '1001',
        //         os: 1,
        //         lang: 'zh-CN',
        //         version: '3.0',
        //         deviceid: 'ef7d6cc05a8f4bdba1b45e6dd2e6b0be',
        //     }
        //     let langs = 'en'
        //     this.langs = 'en'
        //     let lang = 'zh_CN'
            document.title = this.$t('ramadan_signin_title')
            if (langs == "ar") {
                this.$i18n.locale = "ar";
            } else if(langs == "tr") {
                this.$i18n.locale = "tr";
            } else {
                this.$i18n.locale = "en";
            }
            document.querySelector("html").setAttribute("lang", this.$i18n.locale);
            this.getActInfo()
        },
        // 获取活动信息
        getActInfo(){
            // 获取活动时间
            let personLeveldatas = {
            option: "get",
            host:
                this.$serviceIpJava +
                "api/activity/ramadan/signin/init?token=" +
                this.token +
                "&uid=" +
                this.uid,
            data: "",
            lang: this.langs,
            header: this.headers,
            };
            this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
            if (data.code == 200) {
                this.rewards = data.data.rewards;
                this.signs = data.data.signs;
                this.days = data.data.days;
                this.curday = data.data.curday;
                this.$nextTick(()=>{
                    this.ensureChildInView()
                })
            }else{
                Toast(data.message);
            }
            });
        },
        //控制当前天展示出来
        ensureChildInView() {
            const childElement = this.$refs.fixedChild[0];
            const parentElement = this.$refs.scrollParent;

            if (!childElement || !parentElement) return; // 确保元素存在
            // 计算子元素相对于父元素顶部的实际距离
            let childTopRelativeToParent = 0;
            let currentElement = childElement;
            while (currentElement && currentElement !== parentElement) {
                childTopRelativeToParent += currentElement.offsetTop;
                currentElement = currentElement.offsetParent;
            }

            // 获取父元素当前的scrollTop值
            const scrollTop = parentElement.scrollTop;
            // 子元素底部相对于父元素顶部的距离
            const childBottomRelativeToParent = childTopRelativeToParent + childElement.offsetHeight;

            // 判断子元素是否完全位于父元素的可视区域内
            if (childTopRelativeToParent < scrollTop || childBottomRelativeToParent > scrollTop + parentElement.clientHeight) {
                // 强制重排
                void parentElement.offsetHeight;
                requestAnimationFrame(() => {
                // 设置父元素的scrollTop属性，使得子元素顶部对齐到父元素的顶部
                parentElement.scrollTop = childTopRelativeToParent - parentElement.offsetTop;
                console.log(`Updated scrollTop: ${parentElement.scrollTop}`);
                });
            }
        }
    }
  };
  </script>
  
  <style scoped>
  @import './index.css';
  </style>
  